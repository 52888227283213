/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // WebFontLoader
        WebFont.load({
          google: {
            families: ['Open+Sans:400italic,400,700:latin']
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var $slides = $('.front-page-carousel .item');
        if ($slides.length > 0) {
        	$slides.eq(0).addClass('active');
        	//$slides.carouselHeight();
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'opdrachtgevers': {
      init: function() {
        // JavaScript to be fired on the about us page
        	// Opdrachtgevers slideshow
				$('#logo-slider').bxSlider({
					mode: 'horizontal',
					minSlides: 2,
					maxSlides: 4,
					slideWidth: 150,
					slideMargin: 20,
					moveSlides: 1,
					pause: 3000,
					auto: true,
					infiniteLoop: true,
					speed: 800,
					pager: false,
					controls: false
				});

				//Opdrachtgevers multi-column
				$('.opdrachtgevers-content').find('table, thead, tbody, tfoot, colgroup, caption, label, legend, script, style, textarea, button, object, embed, tr, th, td, ul, li, h1, h2, h3, h4, h5, h6, form').addClass('dontsplit');
				$('.opdrachtgevers-content').find('h1, h2, h3, h4, h5, h6').addClass('dontend');
				$('.opdrachtgevers-content').find('br').addClass('removeiflast').addClass('removeiffirst');
				$('.opdrachtgevers-content').columnize({ columns: 3 });
				
				//Opdrachtgevers post pop-up
				$('#opdrachtgeverModal').on('show.bs.modal', function (event) {
					var triggerEl = $(event.relatedTarget);
					var clientid = triggerEl.data('client');
					
					var modal = $(this);
					modal.find('.modal-content').load('//www.laatzienwiejebent.nl/ajax-handler/?id='+clientid);
				});
				
				$('#opdrachtgeverModal').on('hidden.bs.modal', function (event) {
					var modal = $(this);
					modal.find('.modal-title').html('Laden...');
					modal.find('.modal-body').html('Een ogenblik geduld.');
				});
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
